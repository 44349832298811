.legend {
  margin-top: 1em;
  font-size: 0.8em;
  color: var(--text-color);
}

.legendItem {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 0.5em;
  overflow: hidden;
}

.legendHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  background-color: var(--form-background);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.legendHeader:hover,
.legendHeader.open {
  background-color: var(--input-background);
}

.colorDot {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.5em;
}

.activityTitle {
  flex-grow: 1;
  color: var(--primary-color);
}

.activityDuration {
  margin-right: 0.5em;
  color: var(--primary-color);
}

.expandIcon {
  transition: transform 0.2s ease;
  color: var(--primary-color);
}

.expandIconOpen {
  transform: rotate(180deg);
}

.timeBlockListWrapper {
  background-color: var(--background-color);
  overflow: hidden;
}

.timeBlockListWrapper[data-state="open"] {
  animation: slideDown 0.3s ease-out;
}
.timeBlockListWrapper[data-state="closed"] {
  animation: slideUp 0.3s ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.timeBlockList {
  margin: 0.5em;
}

.timeBlockItem {
  font-size: 0.9em;
  color: var(--primary-color);
  margin-bottom: 1em;
  opacity: 0;
  transform: translateY(-1em);
  transition: all 1s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-variant-numeric: tabular-nums;
}

[data-state="open"] .timeBlockItem {
  opacity: 1;
  transform: translateY(0);
}

.deleteButton {
  width: fit-content;
  border-radius: 50%;
  background-color: var(--error-color);
  color: white;
  border: none;
  padding: 3px 4px 1px;
  font-size: 0.8em;
  cursor: pointer;
  margin: 0 0.5em;
}

.deleteButton:active,
.deleteButton:active:not(:disabled),
.deleteButton:hover,
.deleteButton:hover:not(:disabled) {
  background-color: var(--error-hover-color);
}

.badge {
  width: fit-content;
  background-color: white;
  padding: 2px 8px;
  font-size: 0.8em;
  border-radius: 1em;
}
