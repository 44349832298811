.tooltipContent {
  padding: 0.25em 1em;
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.8em;
  background-color: white;
  transform-origin: var(--radix-tooltip-content-transform-origin);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 3000;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
}

.tooltipArrow {
  fill: white;
}

.tooltipArrow::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}

/* Add a subtle animation for smoother appearance */
.tooltipContent[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.tooltipContent[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.tooltipContent[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.tooltipContent[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.tooltipContent {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.colorDot {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}
