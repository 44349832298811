.button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 9px;
}

.button svg {
  stroke-width: 2.5;
}
