.titleText {
  flex-grow: 1;
  padding: 0.5em;
  cursor: pointer;
  font-size: 0.8em;
}

.titleText.disabled {
  cursor: default;
}

.titleInput {
  flex-grow: 1;
  padding: 0.5em;
  position: relative;
  top: 1px;
  right: 1px;
}
