.alphaBadge {
  display: inline-block;
  font-size: 0.6em;
  font-weight: normal;
  padding: 3px 10px;
  border-radius: 1em;
  background-color: var(--form-background);
  color: var(--text-color);
  vertical-align: middle;
}
