.settingsButton {
  width: auto;
  display: flex;
  align-items: center;
}

.icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

.section {
  border-radius: 10px;
  background-color: var(--form-background);
  box-shadow: inset 0 -2px 0 rgba(30, 30, 46, 0.15);
  padding: 0.5em 1em;
  margin-bottom: 1em;
}

.section:last-of-type {
  margin-bottom: 0;
}

.sectionTitle {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--primary-color);
}

.activityTypeList {
  list-style: none;
  padding: 0;
}

.activityTypeRow {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  height: 3em;
  padding: 0.5em;
  gap: 0.5em;
  border-radius: 10px;
  /* box-shadow: inset 0 -2px 0 rgba(30, 30, 46, 0.15); */
}

.activityTypeRow:nth-of-type(even) {
  background-color: rgb(245, 245, 245);
}

.activityTypeRow:nth-of-type(odd) {
  background-color: var(--background-color);
}

.archivedType {
  opacity: 0.6;
  background-color: #f0f0f0 !important;
}

.archivedType .colorButton {
  filter: grayscale(70%);
}

.colorButton {
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.hiddenColorInput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}

.hiddenColorInput:disabled {
  opacity: 0;
}

.resetOptions {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.dangerButton {
  background-color: var(--error-color);
  flex-shrink: 0;
}

.dangerButton:hover,
.dangerButton:active,
.dangerButton:hover:not(:disabled),
.dangerButton:active:not(:disabled) {
  background-color: var(--error-hover-color);
}

.helperText {
  margin: 1em 0;
  font-size: 0.8em;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.timeFormatToggle {
  display: flex;
  background-color: var(--form-background);
  border-radius: 5px;
  padding: 4px;
  width: fit-content;
}

.timeFormatOption {
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 0.8em;
  font-weight: bold;
  color: var(--text-color);
  background-color: transparent;
  box-shadow: none;
}

.timeFormatOption:hover:not(.selected) {
  background-color: var(--input-background);
  transform: translateY(-2px);
}

.timeFormatOption.selected {
  background-color: var(--secondary-color);
  color: var(--background-color);
  box-shadow: 0 4px 6px rgba(30, 30, 46, 0.1),
    inset 0 1px 0 rgba(205, 214, 244, 0.15),
    inset 0 -2px 0 rgba(30, 30, 46, 0.15);
}

.timeFormatOption.selected:hover {
  transform: translateY(-3px) scale(1.02);
  box-shadow: 0 7px 14px rgba(30, 30, 46, 0.25),
    inset 0 1px 0 rgba(205, 214, 244, 0.15),
    inset 0 -2px 0 rgba(30, 30, 46, 0.25);
}

.timeFormatOption.selected:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 2px 4px rgba(30, 30, 46, 0.15),
    inset 0 1px 0 rgba(205, 214, 244, 0.05),
    inset 0 -1px 0 rgba(30, 30, 46, 0.15);
}
