@font-face {
  font-family: OhnoSoftieDemo-Black;
  font-style: normal;
  font-weight: bold;
  src: url("/public/fonts/OhnoSoftieDemo-Black.otf") format("opentype");
}

.container {
  margin-top: -20px;
  margin-bottom: 2em;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  display: grid;
  place-items: center;
  user-select: none;
  pointer-events: none;
}

.clockFace,
.hourHand,
.minuteHand {
  font-family: OhnoSoftieDemo-Black;
  font-weight: bold;
  text-anchor: middle;
  dominant-baseline: central;
}

.clockFace {
  position: relative;
  color: rgb(231, 100, 116);
  line-height: 200px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 360px;
}

.hourHand {
  position: relative;
  top: -5px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 120px;
  transform-origin: bottom;
}

.minuteHand {
  position: relative;
  top: -5px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 160px;
  transform-origin: bottom;
}

.hourHand,
.minuteHand {
  line-height: 60px;
  color: black;
  transition: transform 0.5s cubic-bezier(0.4, 2.08, 0.55, 0.44);
}
