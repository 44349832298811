.block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--block-size);
  height: var(--block-size);
  margin: 3px;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  background-color: var(--block-background-color);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(100% - var(--block-initial-fill));
  background-color: var(--block-past-color);
  transition: all 0.3s linear;
  z-index: 1;
}

.current::before {
  animation: fillRemainingBlock linear forwards;
  animation-duration: var(--block-animation-duration);
}

@keyframes fillRemainingBlock {
  from {
    bottom: calc(100% - var(--block-initial-fill));
  }
  to {
    bottom: 0%;
  }
}

.activityBlock {
  background-color: var(--block-background-color);
  animation: bounce 0.3s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0) scale(1);
  }
  30% {
    transform: translateY(-10px) scale(0.8, 1.2);
  }
  50% {
    transform: translateY(-5px) scale(1);
  }
  80% {
    transform: translateY(0) scale(1.2, 0.8);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.past {
  box-shadow: none;
  background-color: var(--block-past-color);
  opacity: 0.7;
}

.preview,
.past.preview,
.past.activityBlock {
  background-color: var(--block-past-color);
}

.past::before {
  height: 0;
  bottom: 0%;
}

.block.current,
.block:hover {
  transform: scale(1.1);
}

.block.selected {
  transition: background-color 0.3s ease;
  transform: scale(1);
  background-color: transparent;
  overflow: hidden;
  box-shadow: none;
}

.block.selected::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: var(--block-size);
  height: var(--block-size);
  background-color: var(--block-background-color);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  filter: blur(10px);
  animation: button-animation 2s linear infinite;
  transition: none;
}

@keyframes button-animation {
  0% {
    transform: rotate(0deg) translate(calc(-1 * var(--block-size) * 0.4))
      rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-1 * var(--block-size) * 0.4))
      rotate(360deg);
  }
}

.hourText {
  z-index: 2;
  font-size: calc(var(--block-size) * 0.45);
  color: var(--block-text-color);
  line-height: 1;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amPm {
  font-size: calc(var(--block-size) * 0.3);
  text-transform: uppercase;
}
