.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-width: fit-content;
}

.blockGroup {
  display: grid;
  grid-template-columns: repeat(8, var(--block-size));
  gap: 3px;
  justify-content: center;
  transition: all 0.3 ease;
}

.currentBlockGroup {
  margin: -0.5em;
  padding: 0.75em;
  border-radius: 1em;
  background-color: rgb(245, 245, 245);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
}
