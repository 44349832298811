.shinyButton {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(
    45deg,
    #f5e0dc,
    #f2cdcd,
    #f5c2e7,
    #cba6f7,
    #f38ba8,
    #eba0ac,
    #fab387,
    #f9e2af,
    #a6e3a1,
    #94e2d5,
    #89dceb,
    #74c7ec,
    #89b4fa,
    #b4befe
  );
  background-size: 400% 400%;
  animation: subtleShift 5s ease-in-out infinite alternate;
}

.buttonText {
  position: relative;
  z-index: 1;
}

.shinyEffect {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.shinyButton:hover .shinyEffect {
  opacity: 1;
}

@keyframes subtleShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
