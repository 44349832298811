.overlay {
  background-color: var(--background-color);
  position: fixed;
  inset: 0;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  z-index: 3000;
}

.content {
  border-radius: 1em;
  background-color: rgb(245, 245, 245);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 600px;
  max-height: 85vh;
  padding: 2.5em;
  overflow-y: auto;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: box-shadow 0.3s ease;
  z-index: 3001;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

.scaleIn {
  animation-name: scaleIn;
}

.scaleOut {
  animation-name: scaleOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.98);
  }
}

.title {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0 0 1em;
  color: var(--primary-color);
}

.closeButton {
  display: flex;
  align-items: center;
  position: absolute;
  top: 1em;
  right: 1em;
  width: 2em;
  height: 2em;
  background: none;
  border-radius: 50%;
  cursor: pointer;
  color: var(--text-color);
  transition: color 150ms ease, transform 150ms ease;
}

.closeButton:hover,
.closeButton:hover:not(:disabled),
.closeButton:active,
.closeButton:active:not(:disabled) {
  background: none;
  color: var(--primary-color);
  transform: scale(1.1);
}

.closeButton:focus {
  outline: 2px solid var(--secondary-color);
}
