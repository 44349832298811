.form {
  max-width: 25em;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--form-background);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow:
    /* Larger drop shadow */ 0 4px 10px rgba(0, 0, 0, 0.2),
    /* Subtle inner shadows for depth */ inset 0 0 1px rgba(0, 0, 0, 0.1),
    inset 0 0 2px rgba(0, 0, 0, 0.05),
    /* Texture effect using many tiny shadows */ 1px 1px 0
      rgba(255, 255, 255, 0.05),
    2px 2px 0 rgba(255, 255, 255, 0.05), 3px 3px 0 rgba(255, 255, 255, 0.05),
    4px 4px 0 rgba(255, 255, 255, 0.05), 5px 5px 0 rgba(255, 255, 255, 0.05),
    6px 6px 0 rgba(255, 255, 255, 0.05), 7px 7px 0 rgba(255, 255, 255, 0.05),
    8px 8px 0 rgba(255, 255, 255, 0.05);
}

.formGroup {
  display: flex;
  flex-direction: row;
}

.formGroup button {
  margin-left: 1em;
  padding: 0 0.75em;
  width: fit-content;
}
