:root {
  --block-size: 1.9em;
  --text-color: rgb(76 79 105);
  --background-color: rgb(239 241 245);
  --primary-color: rgb(91 96 120);
  --secondary-color: rgb(30 102 245);
  --form-background: rgb(220 224 232);
  --input-background: rgb(239 241 245);
  --input-text: rgb(76 79 105);
  --error-color: rgb(243, 139, 168);
  --error-hover-color: rgb(230, 79, 100);
  --disabled-background: rgb(188, 192, 204);
  --disabled-text: rgb(140, 143, 161);
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.25em;
  line-height: 1.5;
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

.app {
  padding: 1.5em;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  position: fixed;
  top: 1em;
  right: 1em;
}

h1 {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--primary-color);
}

h2 {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 20px;
}

header {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;
}

#block-size-select {
  font-size: 0.8em;
  padding: 5px;
  margin: 0 0.5em;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  background-color: var(--input-background);
  color: var(--input-text);
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
  margin-bottom: 2em;
}

.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  align-content: flex-start;
}

#selection-info {
  margin-bottom: 15px;
  font-size: 0.8em;
}

#selection-info span {
  display: block;
  margin-bottom: 5px;
}

#legend {
  margin-top: 1em;
}

#legend p {
  margin: 5px 0;
}

#activity-title {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-size: 0.8em;
}

#activity-duration {
  width: 100%;
  margin-bottom: 10px;
}

#duration-display {
  display: block;
  text-align: center;
  font-weight: bold;
  color: var(--primary-color);
}

input {
  width: 100%;
  padding: 0.5em;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-size: 0.8em;
  background-color: var(--input-background);
  color: var(--input-text);
  transition: opacity 0.25s linear;
}

input:disabled {
  opacity: 0.25;
}

button {
  width: fit-content;
  padding: 10px;
  background-color: var(--secondary-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  font-size: 0.8em;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  box-shadow: 0 4px 6px rgba(30, 30, 46, 0.1),
    /* Outer shadow */ inset 0 1px 0 rgba(205, 214, 244, 0.15),
    /* Top inner highlight */ inset 0 -2px 0 rgba(30, 30, 46, 0.15); /* Bottom inner shadow */
}

button:hover:not(:disabled) {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
  transform: translateY(-3px) scale(1.02);
  box-shadow: 0 7px 14px rgba(30, 30, 46, 0.25),
    inset 0 1px 0 rgba(205, 214, 244, 0.15),
    inset 0 -2px 0 rgba(30, 30, 46, 0.25);
}

button:active:not(:disabled) {
  background-color: #1a59d9; /* Slightly darker on press */
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 2px 4px rgba(30, 30, 46, 0.15),
    inset 0 1px 0 rgba(205, 214, 244, 0.05),
    inset 0 -1px 0 rgba(30, 30, 46, 0.15);
}

button:disabled {
  background-color: var(--disabled-background);
  color: var(--disabled-text);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

#time-left {
  margin-top: 1em;
  font-size: 0.75em;
  color: var(--primary-color);
}

.activity-popover {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  z-index: 10;
}

.activity-popover input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

.activity-popover button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
