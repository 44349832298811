.anchor {
  position: relative;
}

.dropdownList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 8px;
  cursor: pointer;
  font-size: 0.8em;
}

.dropdownItemHighlighted {
  background-color: var(--form-background);
}

.anchor {
  width: 100%;
}

.popoverContent {
  display: none;
  width: var(--radix-popover-trigger-width);
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--input-background);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform-origin: top center;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

.popoverContentOpen {
  display: block;
}

.popoverEntering {
  animation-name: slideDown;
}

.popoverExiting {
  animation-name: slideUp;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
  }
}
